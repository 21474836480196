import React from 'react';
import { connect } from "react-redux";
// import { withStyles, Theme } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
//import Switch from '@material-ui/core/Switch';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import Collapse from '@material-ui/core/Collapse';
// import Tooltip from '@material-ui/core/Tooltip';

import { storeState, svg2ModOptions_update, svg2ModOptions, OptionsValidation } from "./store"
// import { options as option_spec, option_descriptions, option_group } from "./options"
import { create_update_option_action } from "./action-creaters"

// import debounce from "debounce"

// --------------------------------------------------
// connectors
// --------------------------------------------------

interface MappedState {
  options: svg2ModOptions;
  options_validation: OptionsValidation;
}

const mapStateToProps = (state: storeState): MappedState => ({
  options: state.options,
  options_validation: state.options_validation
})

const mapDispatchToProps = (dispatch: { (x: any): void }) => (
  {
    update_parameter: (x: svg2ModOptions_update) => {
      dispatch(create_update_option_action(x))
    }
  }
)

type TextFieldName = "module_name" | "module_value" | "scale_factor" | "precision" | "dpi" | "format" | "units"

interface OptionProps {
  id: TextFieldName;
  label: string;
  options: svg2ModOptions;
  options_validation: OptionsValidation;
  update_parameter: { (x: svg2ModOptions_update): void }
}


// --------------------------------------------------
// Dropdown
// --------------------------------------------------

interface SelectOptionProps extends OptionProps {
  select_options: string[];
}

const _DropDown: React.FC<SelectOptionProps> = ({ id, label, update_parameter, options, options_validation, select_options }) => (
  //<ListItem>
  <FormControl>
    <InputLabel >{label}</InputLabel>
    <Select
      // labelId="demo-simple-select-label"
      error={(id in options_validation)}
      id={id}
      value={options[id]}
      onChange={e => update_parameter({ [id]: e.target.value })} // debounce(e => update_parameter({ [id]: e.target.value }),200)
    >
      {select_options.map((value) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
    </Select>
  </FormControl>
  //</ListItem>
)

/*

 <TextField
          error
          id="outlined-error-helper-text"
          label="Error"
          defaultValue="Hello World"
          helperText="Incorrect entry."
          variant="outlined"
        />
*/

const DropDownOption = connect(mapStateToProps, mapDispatchToProps)(_DropDown);


// --------------------------------------------------
// TextField
// --------------------------------------------------


const _TextOption: React.FC<OptionProps> = ({ id, label, update_parameter, options, options_validation }) => (
  <ListItem>
    <TextField
      error={(id in options_validation)}
      id={id}
      label={label}
      value={options[id]}
      onChange={e => update_parameter({ [id]: e.target.value })} // debounce(e => update_parameter({ [id]: e.target.value }),200)
      helperText={options_validation[id]}
    />
  </ListItem>)

/*

 <TextField
          error
          id="outlined-error-helper-text"
          label="Error"
          defaultValue="Hello World"
          helperText="Incorrect entry."
          variant="outlined"
        />
*/

const TextOption = connect(mapStateToProps, mapDispatchToProps)(_TextOption);

// --------------------------------------------------
// Radio Buttons
// --------------------------------------------------

// interface RadioProps {
//   id: keyof svg2ModOptions;
//   label: string;
//   options_list: string[];
//   options: svg2ModOptions;
//   update_parameter: { (x: svg2ModOptions_update): void }
// }

// const _RadioOption: React.FC<RadioProps> = ({ id, label, options, options_list, update_parameter }) => (
//   <ListItem>
//     <FormControl component="fieldset">
//       <FormLabel component="legend">{label}</FormLabel>
//       <div>
//         {options_list.map(opt => (
//           <Button
//             key={opt}
//             style={{ margin: "0.5rem" }}
//             variant={opt === options[id] ? "contained" : "outlined"}
//             size="small"
//             color="primary"
//             onClick={e => update_parameter({ [id]: opt })}>{opt}</Button>))}
//       </div>
//     </FormControl>
//   </ListItem>
// )

// const RadioOption = connect(mapStateToProps, mapDispatchToProps)(_RadioOption);

// --------------------------------------------------
// Checkbox
// --------------------------------------------------

interface CheckProps {
  id: keyof svg2ModOptions;
  label: string;
  options: svg2ModOptions;
  update_parameter: { (x: svg2ModOptions_update): void }
}

const _CheckOption: React.FC<CheckProps> = ({ id, label, options, update_parameter }) => (
  <ListItem>
    <FormControl component="fieldset">
      <FormControlLabel
        control={<Checkbox
          id={id}
          checked={options[id] as boolean}
          onChange={e => { update_parameter({ [id]: e.target.checked }) }}
          name={id}
        />}
        label={label}
      />
    </FormControl>
  </ListItem>
)

const CheckOption = connect(mapStateToProps, mapDispatchToProps)(_CheckOption);



// --------------------------------------------------
// Main Options panel
// --------------------------------------------------

interface formOptions {
  options: svg2ModOptions;
}

const OptionsForm: React.FC<formOptions> = ({ options }) => (
  <form>
    <List>
      <TextOption id="module_name" label="Module Name" />
      <TextOption id="module_value" label="Module Value" />
      <TextOption id="scale_factor" label="Scaling Factor" />
      <TextOption id="precision" label="Precision" />
      <TextOption id="dpi" label="dpi" />
      <ListItem>
        <div style={{ minWidth: "120px", whiteSpace: "nowrap" }}>
          <DropDownOption id="format" label="Module Format" select_options={["pretty", "legacy"]} />
        </div>
        {options.format === "legacy" && <DropDownOption id="units" label="Units" select_options={["mm", "decimal"]} />}
      </ListItem>
      <CheckOption id="center" label="Center the module in the middle of the bouding box" />
      <CheckOption id="ignore_hidden_layers" label="Exclude hidden layers from output" />
      <CheckOption id="convert_to_pads" label="Convert drawings on Cu layers to pads" />
      <CheckOption id="preprocess_object" label="Preprocess with Inkscape Object-to-Path" />
      <CheckOption id="preprocess_stroke" label="Preprocess with Inkscape Stroke-to-Path" />
    </List>
  </form>
)

/*

      <TextOption id="dpi" label="Dots per Inch" />
      <RadioOption id="format" label="Format" options_list={["pretty", "legacy",]} />
      {(options.format === "legacy") && <RadioOption id="units" label="Units" options_list={["mm", "decimil"]} />}
*/
export default connect(mapStateToProps)(OptionsForm);
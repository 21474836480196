import React from 'react';
import { storeState } from "./store"
// import GetAppIcon from '@material-ui/icons/GetApp';
// import Button from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";

interface downloadProps {
    contents?: any;
    filename?: string;
}

function download(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

const Download: React.FC<downloadProps> = ({ filename, contents }) => {
    return (
        <Button
            disabled={!contents}
            variant="outlined"
            color="inherit"
            size="small"
            onClick={() => { download(filename!, contents,) }}>
            Download
        </Button>
    )
}

function mapStateToProps(state: storeState): downloadProps {
    return {
        contents: state.module_contents,
        filename: state.new_path,
    };
}

export default connect(mapStateToProps)(Download);

import React from 'react';
import { connect } from "react-redux";
import { storeState } from "./store"
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress'
import SaveIcon from '@material-ui/icons/Save';
import { KicadModule } from "react-kicad-module"
// import LayerNames from "./layer-names"


const useStyles = makeStyles(theme => ({
    error: {
        paddingBottom: "1rem",
    },
    paper: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        margin: "3em"
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: ("relative" as "relative"), // obsurd code to satisfy tsc
    },
    fabProgress: {
        position: ("absolute" as "absolute"), // obsurd code to satisfy tsc
        top: -6,
        left: -6,
        zIndex: 1,
    },
}));

const Main: React.FC<mainProps> = (props) => {

    const classes = useStyles();

    if (props.error_message) {
        return (<Typography color="error">{props.error_message}</Typography>)
    }
    if (props.module_success) {
        return (
            <>
                {props.module_format === "pretty" && <KicadModule module={props.module} />}
                <p>This illustration is only an approximation. Donownload your module and import it into Kicad for acutal results.
                    {props.pads && (<strong> In particular, pads are not rendered well in this rendering</strong>)}</p>
                <hr />
                <div style={{ fontFamily: "'Roboto Mono', monospace" }} ><pre>{props.module}</pre></div>
            </>
        )
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="save"
                        color="primary"
                    >
                        <SaveIcon />
                    </Fab>
                    {props.loading && <CircularProgress size={68} className={classes.fabProgress} />}

                </div>
            </div>
            <div className={classes.paper}>
                <Paper>
                    <div className={classes.content}>
                        <h2>How to use</h2>
                        <Typography>Want to bring your Designs into Kicad? Great!
                            Create your beautiful designs using <a href="https://inkscape.org/release/inkscape-1.0/" className="href">InkScape</a>, and drop them onto the page.
                            Layers in your svg file will be mapped into a Kicad module that you can import onto your favorite PCB.
                            Create a layer in InkScape using the <a href="https://wiki.inkscape.org/wiki/index.php/Layer_Dialog" target="_blank" rel="noreferrer">layer dialog</a> and it will be included in the layer with the same name (e.g. <code>F.Cu"</code>) of your new Kicad module!</Typography>
                        <Typography style={{ marginTop: "1em" }}>More details can be found <a href="https://github.com/svg2mod/svg2mod#usage" target="_blank" rel="noreferrer">here</a> </Typography>
                    </div>
                </Paper>
            </div>

            <div className={classes.paper}>
                <Paper>
                    <div className={classes.content}>
                        <h2>About this Site</h2>
                        <Typography>This site sends Inkscape SVG files to a backend server, compiles them with svg2mod and the results are displayed here. Documents are encrypted in transit and nothing is ever stored on the server.  However, if your designs are precious and you don't trust the internets, then head over to <a href="https://github.com/svg2mod/svg2mod" style={{ textDecoration: 'none' }}>GitHub</a> and compile them from the comfort your own machine.</Typography>
                    </div>
                </Paper>
            </div>
        </>
    )

}

interface mainProps {
    module_success?: boolean;
    module?: any;
    error_message?: string;
    loading: boolean;
    module_format: "legacy" | "pretty";
    pads: boolean;
}

function mapStateToProps(state: storeState): mainProps {
    return {
        module_success: state.module_success,
        module: state.module_contents,
        error_message: state.error_message,
        loading: state.loading,
        module_format: (state.prev_options ? state.prev_options.format : "legacy"),
        pads: state.options.convert_to_pads
    };
}

export default connect(mapStateToProps)(Main);

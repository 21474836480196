import React from 'react';
import { storeState } from "./store"
import { process_content_action } from "./action-creaters"
import equal from "fast-deep-equal"
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";

interface iUpdateProps {
    disabled: boolean;
    onClick: { (): void }
}

const Update: React.FC<iUpdateProps> = ({ disabled, onClick }) => {
    return (
        <Button
            disabled={disabled}
            style={disabled ? { backgroundColor: "#e3e3e3", borderColor: "red" } : {}}
            variant={disabled ? "contained" : "outlined"}
            color={"inherit"}
            size="small"
            onClick={onClick}>
            Update
        </Button>
    )
}

const mapStateToProps: { (state: storeState): { disabled: boolean; } } = (state: storeState) => (
    {
        disabled: (
            (!state.prev_options) ||
            (!state.module_contents) ||
            equal(state.options, state.prev_options)
        )
    }
)

const mapDispatchToProps: { (dispatch: { (x: any): void }): { onClick: { (): void } } } = (dispatch: { (x: any): void }) => (
    {
        onClick: () => dispatch(process_content_action())
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(Update);

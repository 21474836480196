"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.len = exports.diff = exports.multiply = exports.add = exports.rotate = void 0;
const rotate = (a, b, r) => {
    // rotate b about a by r degrees
    const c = Math.cos((Math.PI * r) / 180);
    const s = Math.sin((Math.PI * r) / 180);
    const dx = exports.add(b, exports.multiply(-1, a));
    return [c * dx[0] - s * dx[1] + a[0], s * dx[0] + c * dx[1] + a[1]];
};
exports.rotate = rotate;
const add = (x, y) => [x[0] + y[0], x[1] + y[1]];
exports.add = add;
const multiply = (x, y) => [x * y[0], x * y[1]];
exports.multiply = multiply;
const diff = (x, y) => exports.add(x, exports.multiply(-1, y));
exports.diff = diff;
const len = (x, y) => {
    if (y)
        x = exports.add(x, exports.multiply(-1, y));
    return Math.sqrt(x[0] * x[0] + x[1] * x[1]);
};
exports.len = len;

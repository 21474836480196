import thunkMiddleware, { ThunkAction } from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";

export type action =
    | set_path_action
    | set_module_action
    | set_raw_svg_action
    | set_transformed_svg_action
    | invalid_file
    | invalid_svg
    | update_options;

export interface storeState {
    file_path?: string;
    new_path?: string;
    file?: File;
    // the transformed svg file
    rawfile?: string;
    txfile?: string;
    txactions?: string[];
    is_invalid?: boolean;
    file_contents?: any;
    error_message?: string;
    options: svg2ModOptions;
    loading: boolean;
    options_validation: OptionsValidation;
    // the previous set of options
    prev_options?: svg2ModOptions;
    // was the request to process the svg into a module successful?
    module_success?: boolean;
    // the body of the module or the error message
    module_contents?: string;
}

export interface OptionsValidation {
    module_name?: string;
    module_value?: string;
    scale_factor?: string;
    precision?: string;
    dpi?: string;
    units?: string;
    format?: string;
}

const is_float = /^[0-9]*\.?[0-9]+$/;
const is_int = /^[0-9]+$/;

export const ValidateOptions = (x: svg2ModOptions): OptionsValidation => {
    const out: OptionsValidation = {};

    if (!x.module_name.length) out.module_name = "required";
    if (!x.module_value.length) out.module_value = "required";
    if (!is_float.test(x.scale_factor))
        out.scale_factor = "Must be a valid float";
    if (!is_float.test(x.precision)) out.precision = "Must be a valid float";
    if (!is_int.test(x.dpi)) out.dpi = "Must be an integer";

    return out;
};

const default_options: svg2ModOptions = {
    format: "pretty",
    module_name: "MyModule",
    module_value: "V***",
    ignore_hidden_layers: true,
    convert_to_pads: false,
    preprocess_object: false,
    preprocess_stroke: false,
    units: "mm",
    dpi: "96",
    center: true,
    scale_factor: "1",
    precision: "1",
};

// {
//     module_name: "",
//     module_value: "",
//     scale_factor: "1",
//     precision: "0.1",
//     dpi: "96",
//     units: "mm",
//     format: "pretty",
//     center: true,
// }

export const coerce_options = (x: svg2ModOptions): pythonSvg2ModOptions => {
    const {
        module_name,
        module_value,
        scale_factor,
        precision,
        // dpi,
        center,
    } = x;

    const out: pythonSvg2ModOptions = {
        module_name,
        module_value,
        scale_factor: parseFloat(scale_factor),
        precision: parseFloat(precision),
        // dpi: parseInt(dpi),
        center,
    };
    return out;
};

export interface pythonSvg2ModOptions {
    module_name: string;
    module_value: string;
    scale_factor: number;
    precision: number;
    // dpi: number;
    center: boolean;
}

type module_format = "pretty" | "legacy";
type module_units = "mm" | "decimal";

export interface svg2ModOptions {
    module_name: string;
    module_value: string;
    scale_factor: string;
    precision: string;
    dpi: string;
    units: module_units;
    format: module_format;
    center: boolean;
    convert_to_pads: boolean;
    ignore_hidden_layers: boolean;
    preprocess_object: boolean;
    preprocess_stroke: boolean;
}

export interface svg2ModOptions_update {
    module_name?: string;
    module_value?: string;
    scale_factor?: string;
    precision?: string;
    dpi?: string;
    units?: module_units;
    format?: module_format;
    center?: boolean;
    convert_to_pads?: boolean;
    ignore_hidden_layers?: boolean;
    preprocess_object?: boolean;
    preprocess_stroke?: boolean;
}

export interface invalid_svg {
    type: "INVALID_SVG";
    txfile: string;
    options: svg2ModOptions;
    message: string;
}

// export interface set_contents {
//     type: "SET_CONTENTS";
//     path: string;
//     options: svg2ModOptions;
//     payload: string;
// }

export interface invalid_file {
    type: "INVALID_FILE";
    file_path: string;
    message: string;
}

export interface set_path_action {
    type: "SET_PATH";
    file: File;
    file_path: string;
}

export interface set_transformed_svg_action {
    type: "SET_TRANSFORMED_SVG";
    rawfile: string;
    actions: string[];
    payload: string;
}
export interface set_raw_svg_action {
    type: "SET_RAW_SVG";
    payload: string;
}

export interface set_module_action {
    type: "SET_MODULE";
    // rawfile: string;
    options: svg2ModOptions;
    payload: any;
}
export interface set_tx_module_action {
    type: "SET_TX_MODULE";
    actions: string[];
    payload: any;
}

export interface update_options {
    type: "UPDATE_OPTIONS";
    payload: svg2ModOptions_update;
}

const dot_svg = /\.svg$/;

function reducer(
    state: storeState = {
        options: default_options,
        loading: false,
        options_validation: ValidateOptions(default_options),
    },
    action: action
): storeState {
    switch (action.type) {
        case "SET_PATH":
            return {
                ...state,
                file: action.file,
                file_path: action.file_path,
                new_path: action.file_path.replace(
                    dot_svg,
                    state.options.format === "pretty" ? ".kicad_mod" : ".mod"
                ),
                is_invalid: false,
                file_contents: null,
                error_message: undefined,
                loading: true,
                module_success: undefined,
                module_contents: undefined,
            };
        case "SET_RAW_SVG":
            return {
                ...state,
                rawfile: action.payload,
            };
        case "SET_TRANSFORMED_SVG":
            if (action.rawfile !== state.rawfile) {
                console.log("Expired process request: SET_TRANSFORMED_SVG");
                return state;
            }
            return {
                ...state,
                txfile: action.payload,
                txactions: action.actions,
            };
        case "INVALID_SVG":
            // TODO: FIXME: this should be carefuly reinstated
            // if (action.txfile !== state.txfile) {
            //     console.log("Expired process request: INVALID_SVG");
            //     return state;
            // }
            return {
                ...state,
                is_invalid: true,
                error_message: action.message,
                loading: false,
            };
        case "INVALID_FILE":
            return {
                ...state,
                file_path: action.file_path,
                is_invalid: true,
                error_message: action.message,
                loading: false,
            };
        case "SET_MODULE":
            // TODO: FIXME: reinstate (carefully)
            // if (
            //     state.txfile !== action.txfile ||
            //     state.options !== action.options
            // ) {
            //     console.log("OUT OF DATE RESPONSE");
            //     return state;
            // }
            return {
                ...state,
                is_invalid: false,
                error_message: undefined,
                loading: false,
                module_success: action.payload.success,
                module_contents: action.payload.body,
                prev_options: { ...action.options },
            };
        case "UPDATE_OPTIONS": {
            const svg2ModOptions: svg2ModOptions = {
                ...state.options,
                ...action.payload,
            };

            let out: storeState = {
                ...state,
                error_message: undefined,

                options: svg2ModOptions,
                new_path:
                    state.file_path &&
                    state.file_path.replace(
                        dot_svg,
                        state.options.format === "pretty"
                            ? ".kicad_mod"
                            : ".mod"
                    ),
                options_validation: ValidateOptions(svg2ModOptions),
            };
            return out;
        }

        default:
            //alert("unhandled action type: " + (action as any).type);
            return state;
    }
}

export type ThunkResult<R> = ThunkAction<R, storeState, undefined, action>;

const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    reducer,
    {
        options: default_options,
        loading: false,
        options_validation: ValidateOptions(default_options),
    },
    composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;

/// <reference path="./github-tag.d.ts" />

import React from 'react';
import { connect } from "react-redux";
import { storeState } from "./store"
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import OptionList from "./option-list"
import DropZone from "./dropzone"
import GitHubForkRibbon from "react-github-fork-ribbon"
import Main from "./main"
import Download from "./download"
import Update from "./update"
import indigo from '@material-ui/core/colors/indigo';

// import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
// import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    position: "relative",
  },
  downloads: {
    backgroundColor: indigo[500],
    minHeight: "5rem",
    padding: "1rem",
    color: "#fff",
    bottom: "0px",
  },
  filename: {
    marginBottom: ".5rem"
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  red: {
    color: theme.palette.secondary.dark
  },
  headerSpan: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  handle: {
    paddingRight: '0.6rem'
  },
}));

interface appProps {
  file_path?: string;
  new_path?: string;
  has_contents: boolean;
}

const App: React.FC<appProps> = (props) => {
  // const [open, setOpen] = React.useState(false);
  // const toggleOpen = () => { setOpen(!open) };
  const classes = useStyles();

  const MyDrawer = (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <OptionList />

      <div className={classes.downloads} style={{ marginTop: "auto" }}>
        {(props.file_path && !props.file_path.endsWith(".svg")) ?
          <span key="warning" className={classes.red}>[not a .svg file]</span> :
          null}

        {props.has_contents ?
          <div style={{ position: "relative", top: "50%", transform: "translateY(-50%)", display: "flex", justifyContent: "space-around" }}>
            {/* <Typography variant="h6" noWrap className={classes.filename}>
              <span>{props.new_path}</span>
            </Typography> */}
            <div style={{ marginRight: "1.6rem", display: "inline-block" }}>
              <Update />
            </div>
            <Download />
          </div>
          : null}
      </div>

    </Drawer>
  )


  return (

    <DropZone>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <GitHubForkRibbon href="//github.com/svg2mod/svg2mod#readme"
            target="_blank"
            position="right"
            color="black">
            Fork me on GitHub
          </GitHubForkRibbon>
          <Toolbar>
            <Typography variant="h6" noWrap>
              Inkscape to Kicad Module Converter
            </Typography>
          </Toolbar>
        </AppBar>
        {MyDrawer}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Main />
          <div className={classes.toolbar} />
        </main>
      </div>
    </DropZone>
  )
}

function mapStateToProps(state: storeState): appProps {
  return {
    file_path: state.file_path,
    new_path: state.new_path,
    has_contents: !!state.module_contents,
  };
}

export default connect(mapStateToProps)(App);
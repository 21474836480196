import axios from "axios";
// import { ThunkResult } from "redux-thunk";

import {
    ThunkResult,
    action,
    // set_module_action,
    update_options,
    svg2ModOptions_update,
    storeState,
    svg2ModOptions,
    // svg2ModOptions,
} from "./store";

interface actionCreator {
    (...args: any[]): action;
}

const VERBOSE = true;
const INVALID_FILE_MESSAGE =
    "This does not appear to be a valid Inkscape SVG file.  Note that this software is particular to the normal (not compressed) Inkscape format.";

export function create_path_action(
    file_path: string,
    file: File
): ThunkResult<void> {
    return async (dispatch: { (x: action | ThunkResult<void>): void }) => {
        if (!file_path.endsWith(".svg")) {
            dispatch({
                type: "INVALID_FILE",
                file_path,
                message: "Not an svg file",
            });
            return;
        }
        dispatch({ type: "SET_PATH", file_path, file });

        if (!file.size) {
            dispatch({
                type: "INVALID_FILE",
                file_path,
                message: "Empty file (Zero bytes received)",
            });
            return;
        }

        try {
            // @ts-ignore
            const contents = await file.text();

            dispatch({
                type: "SET_RAW_SVG",
                payload: contents,
            });
            VERBOSE && console.log("about to process file");
            dispatch(process_content_action(contents));
        } catch (err) {
            dispatch({
                type: "INVALID_FILE",
                file_path,
                message: "Unable to connect to the backend server",
            });
        }
    };
}

// export function transform_file_action(
//     rawfile: string,
//     actions: string[]
// ): ThunkResult<void> {

//         //console.log("response.data:", response.data);
//         if (response.statusText === "OK") {
//             dispatch({
//                 type: "SET_TRANSFORMED_SVG",
//                 file_path,
//                 // new_path: file_path.slice(0, -3) + "kicad_mod",
//                 payload: response.data,
//             });

//             dispatch(process_content_action(response.data));
//         } else {
//             dispatch({
//                 type: "INVALID_FILE",
//                 file_path,
//                 message: response.data.message,
//             });
//             return;
//         }
//     };
// }

const get_actions = (x: svg2ModOptions): string[] =>
    [
        x.preprocess_object && "ObjectToPath",
        x.preprocess_stroke && "StrokeToPath",
    ].filter((x) => !!x) as string[];

export function process_content_action(content?: string): ThunkResult<void> {
    console.log("starting process_content_action");
    return async (
        dispatch: { (x: action): void },
        getState: { (): storeState }
    ) => {
        console.log("processing process_content_action");

        // --------------------------------------------------
        // ARGS
        // --------------------------------------------------
        var { options, txactions, txfile, rawfile, file_path } = getState();
        if (
            typeof file_path === "undefined" ||
            typeof rawfile === "undefined"
        ) {
            return;
        }

        // --------------------------------------------------
        /// PREPROCESS WITH INKSCAPE
        // --------------------------------------------------

        const actions = get_actions(options);
        VERBOSE && console.log("options: ", options);
        VERBOSE && console.log("actions: ", actions);
        const prev_actions = txactions;

        if (!actions.length) {
            // no preprocessing
            console.log("[USIGG raw file]  actions:", actions);
            content = rawfile;
        } else if (
            prev_actions &&
            actions.join("") === prev_actions.join("") &&
            typeof txfile !== undefined
        ) {
            console.log("[USIGG PREV TXFILE] prev_actions:", prev_actions);
            console.log("[USIGG PREV TXFILE] actions:", actions);
            // use the previous pre=processing
            content = txfile;
        } else {
            console.log("[PRE-PROCESSING FILE] prev_actions:", prev_actions);
            console.log("[PRE-PROCESSING FILE] actions:", actions);
            // do the pre-processing now
            //const contents =
            const ttp_formData = new FormData();

            ttp_formData.append(
                "file",
                new File([rawfile], "foo.svg", {
                    type: "text/plain",
                })
            );
            try {
                var ttp_response = await axios.post(
                    "/api/text-to-path",
                    ttp_formData,
                    {
                        params: { actions },
                        headers: { "Content-Type": "multipart/form-data" },
                    }
                );
            } catch (err) {
                if (err.response.status === 404) {
                    dispatch({
                        type: "INVALID_FILE",
                        file_path,
                        message: "Unable to connect to the backend server",
                    });
                    return;
                }
                dispatch({
                    type: "INVALID_FILE",
                    file_path,
                    message: INVALID_FILE_MESSAGE,
                });
                return;
            }

            if (ttp_response.statusText !== "OK") {
                dispatch({
                    type: "INVALID_FILE",
                    file_path,
                    message: ttp_response.data.message,
                });
                return;
            }
            dispatch({
                type: "SET_TRANSFORMED_SVG",
                rawfile,
                actions,
                payload: ttp_response.data,
            });

            content = ttp_response.data;
        }
        // --------------------------------------------------
        /// PREPROCESS WITH INKSCAPE
        // --------------------------------------------------

        const _options: any = { ...options };
        _options.dpi = parseFloat(_options.dpi);
        _options.precision = parseFloat(_options.precision);
        _options.scale_factor = parseFloat(_options.dpi);

        if (typeof content === "undefined") {
            console.log("process_content_action called prematurely");
            return;
        }

        const formData = new FormData();
        formData.append("options", JSON.stringify(_options));
        formData.append("file", content);

        try {
            var response = await axios.post("/api/svg2mod", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        } catch (err) {
            if (err.response.status === 404) {
                dispatch({
                    type: "INVALID_SVG",
                    txfile: content,
                    options,
                    message:
                        "[/api/svg2mod] Unable to connect to the backend server",
                });
                return;
            }
            dispatch({
                type: "INVALID_SVG",
                txfile: content,
                options,
                message: INVALID_FILE_MESSAGE,
            });
            return;
        }

        //console.log("response.data:", response.data);
        if (response.statusText === "OK") {
            dispatch({
                type: "SET_MODULE",
                // : content,
                options,
                // new_path: file_path.slice(0, -3) + "kicad_mod",
                payload: response.data,
            });
        } else {
            dispatch({
                type: "INVALID_SVG",
                txfile: content,
                options,
                message: response.data.message,
            });
            return;
        }
    };
}

export const drop_error: actionCreator = (message: string) => ({
    type: "INVALID_FILE",
    file_path: "[Drop Error]",
    message: message,
});

export function create_update_option_action(
    update: svg2ModOptions_update
): update_options {
    return {
        type: "UPDATE_OPTIONS",
        payload: update,
    };
}
